import React from "react";
import MyAppBar from "./MyAppBar";
import {
  Container,
  CircularProgress,
  Typography,
  Box,
} from "@material-ui/core";
import { useTranslation } from "../../i18n";
import { I18N_NAMESPACES } from "../i18n_namespaces";

interface MyCircularProgressProps {
  text?: string;
}
const MyCircularProgress: React.FC<MyCircularProgressProps> = ({ text }) => {
  const { t: tCommon } = useTranslation(I18N_NAMESPACES.COMMON);

  if (!text) {
    text = tCommon("Loading");
  }

  return (
    <div style={{ height: "100%", display: "flex", flexDirection: "column" }}>
      <MyAppBar />
      <div style={{ flex: 1, display: "flex" }}>
        <Container
          maxWidth="md"
          style={{
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
            flex: 1,
            flexDirection: "column",
          }}
        >
          <CircularProgress />
          <Box marginTop={1} />
          <Typography>{text}</Typography>
        </Container>
      </div>
    </div>
  );
};

export default MyCircularProgress;
