import React, { useCallback, useState, useLayoutEffect } from "react";
import StyledFirebaseAuth from "react-firebaseui/StyledFirebaseAuth";
import firebaseApp from "../../firebase";
import { useSelector } from "react-redux";
import {
  selectIsAuthenticated,
  selectIsLoggingIn,
} from "../redux/auth/auth.selectors";
import GoogleButton from "react-google-button";
import firebase from "firebase";
import { I18N_NAMESPACES } from "../i18n_namespaces";
import { useTranslation } from "../../i18n";
import SignInWithApple from "./SignInWithApple";
import { Box, Typography } from "@material-ui/core";
import MyCircularProgress from "./MyCircularProgress.component";

const ButtonContainer: React.FC = ({ children }) => {
  return (
    <Box marginY={1} height={50}>
      {children}
    </Box>
  );
};

const SignIn = () => {
  const isAuthenticated = useSelector(selectIsAuthenticated);
  const [isLoggingIn, setIsLoggingIn] = useState(true);
  const [showSignIn, setShowSignIn] = useState(false);
  const { t: tCommon, i18n } = useTranslation(I18N_NAMESPACES.COMMON);

  const onGoogleClick = useCallback(async () => {
    const provider = new firebase.auth.GoogleAuthProvider();
    provider.setCustomParameters({
      prompt: "select_account",
      locale: i18n.language,
    });
    await firebase.auth().signInWithRedirect(provider);
  }, []);

  useLayoutEffect(() => {
    const asyncFunc = async () => {
      const result = await firebase.auth().getRedirectResult();
      if (result.user || firebase.auth().currentUser) {
        setIsLoggingIn(false);
      } else {
        setShowSignIn(true);
      }
    };
    asyncFunc();
  }, []);

  if (isAuthenticated) {
    return null;
  }

  if (isLoggingIn && !showSignIn) {
    return <MyCircularProgress />;
  }

  return (
    <Box padding={1}>
      <Typography variant="h5" gutterBottom>
        {tCommon("Connection")}
      </Typography>
      <ButtonContainer>
        <SignInWithApple />
      </ButtonContainer>
      <ButtonContainer>
        <GoogleButton
          onClick={onGoogleClick}
          label={tCommon("Sign in with Google")}
          lang={i18n.language}
          style={{ width: "100%" }}
        />
      </ButtonContainer>
    </Box>
  );
};

export default SignIn;
