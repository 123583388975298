import React, { useCallback } from "react";
import { Button } from "@material-ui/core";
import AppleIcon from "@material-ui/icons/Apple";
import { I18N_NAMESPACES } from "../i18n_namespaces";
import { useTranslation } from "../../i18n";
import firebase from "firebase";

const SignInWithApple: React.FC = () => {
  const { t: tCommon, i18n } = useTranslation(I18N_NAMESPACES.COMMON);

  const onAppleClick = useCallback(async () => {
    const provider = new firebase.auth.OAuthProvider("apple.com");
    provider.addScope("email");
    provider.addScope("name");
    provider.setCustomParameters({
      // Localize the Apple authentication screen in French.
      locale: i18n.language,
      prompt: "select_account",
    });
    const result = await firebase.auth().signInWithRedirect(provider);
  }, [i18n.language]);

  return (
    <Button
      variant="contained"
      style={{
        backgroundColor: "#000",
        color: "#fff",
        textTransform: "none",
        width: "100%",
        height: "100%",
        fontSize: 16,
      }}
      startIcon={<AppleIcon />}
      onClick={onAppleClick}
    >
      {tCommon("Sign in with Apple")}
    </Button>
  );
};

export default SignInWithApple;
